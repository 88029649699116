import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { ActionFunctionArgs } from "@remix-run/node";
import {
  Form,
  json,
  Links,
  Meta,
  Outlet,
  redirect,
  Scripts,
  ScrollRestoration,
  useMatches,
  useRouteError,
} from "@remix-run/react";
import type { LinksFunction, LoaderFunctionArgs, SerializeFrom } from "@remix-run/node"; // or cloudflare/deno
import styles from "~/tailwind.css?url";
import fonts from "~/fonts.css?url";
import page from "~/page.css?url";
import { alphabetical, useGtm } from "./library/utilities";
import { commitSession, getSession } from "./library/sessions";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: fonts },
  { rel: "stylesheet", href: page },
];

export const useRootLoaderData = () => {
  const [root] = useMatches();
  return root?.data as SerializeFrom<typeof loader>;
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const session = await getSession(
    request.headers.get("Cookie")
  );

  const body = await request.formData();
  const networkEID = body.get("networkEID") || false;
  
  const authenticated = networkEID === process.env.SPEAKAP_NETWORK_EID ? 'true' : 'false'

  if (authenticated === 'false') {
    session.flash("error", "Invalid authentication");

    return json({
      headers: {
        "Set-Cookie": await commitSession(session),
      },
    });
  }

  session.set("authenticated", authenticated);

  return redirect("/vacatures", {
    headers: {
      "Set-Cookie": await commitSession(session, { expires:  new Date(Date.now() + 480_000)}),
    },
  });
};


export const loader = async ({ request }: LoaderFunctionArgs) => {
  const session = await getSession(
    request.headers.get("Cookie")
  );

  const clientId = process.env.CLIENT_ID || ''
  const clientSecret = process.env.CLIENT_SECRET || ''
  const password = process.env.PASSWORD || ''
  const username = process.env.USERNAME || ''
  const grantType = process.env.GRANT_TYPE || ''

  const authenticationResponse: any = await fetch(`https://login.salesforce.com/services/oauth2/token`, {
    method: 'POST',
    body: new URLSearchParams({
      client_id: clientId,
      client_secret: clientSecret,
      password: password,
      username: username,
      grant_type: grantType,
    })
  })

  const authentication = await authenticationResponse.json()

  const jobsResponse = await fetch('https://welten.my.salesforce.com/services/apexrest/byner/BYNER_REST_API/v1/getJobs?internal_channel=Speakap', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${authentication?.access_token}`
    }
  })

  const jobs = await jobsResponse.json()

  const locaties = jobs.map((job: any) => job.job.byner__Job_location__c);
  const expertisegebieden = jobs.map((job: any) => job.job.Vakgebied__c);
  const opdrachtgevers = jobs.map((job: any) => job.account.Name);

  return {
    authenticated: session.has("authenticated"),
    jobs: jobs,
    filters: {
      locaties: [...new Set(locaties)].sort(alphabetical),
      expertisegebieden: [...new Set(expertisegebieden)].sort(alphabetical),
      opdrachtgevers: [...new Set(opdrachtgevers)].sort(alphabetical),
    }
  };
};

export function Layout({ children }: { children: React.ReactNode }) {
  useGtm();

  return (
    <html lang="nl-NL">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `
              <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MNMKR9RK" 
              height="0" width="0" style="display: none; visibility: hidden;"></iframe>
            `,
          }}
        />

        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}