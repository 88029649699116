import { useLocation } from '@remix-run/react';
import { useEffect } from 'react';
import { composeRenderProps } from 'react-aria-components';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

export const focusRing = tv({
  base: 'u-outline u-outline-blue-600 dark:u-outline-blue-500 forced-colors:u-outline-[Highlight] u-outline-offset-2',
  variants: {
    isFocusVisible: {
      false: 'u-outline-0',
      true: 'u-outline-2'
    }
  }
});

export function composeTailwindRenderProps<T>(className: string | ((v: T) => string) | undefined, tw: string): string | ((v: T) => string) {
  return composeRenderProps(className, (className) => twMerge(tw, className));
}

export const handleize = (str: string) => {
  str = str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove accents
    .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
    .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
    .replace(/(^-+|-+$)/g, '') // Remove extra hyphens from beginning or end of the string
    .toLowerCase(); // To lowercase

  return str;
};

export const transformName = (string: string) => {
  const handleizedString = handleize(string)

  if (handleizedString.includes(handleize('rabobank nederland'))) return 'Rabobank'
  if (handleizedString.includes(handleize('abn amro bank n.v.'))) return 'ABN AMRO'
  if (handleizedString.includes(handleize('de volksbank n.v.'))) return 'De Volksbank'
  if (handleizedString.includes(handleize('ing bank n.v'))) return 'ING'
  if (handleizedString.includes(handleize('a.s.r. verzekeringen'))) return 'a.s.r Verzekeringen'
  if (handleizedString.includes(handleize('stater n.v'))) return 'Stater'
  if (handleizedString.includes(handleize('quion groep bv'))) return 'Quion'
  if (handleizedString.includes(handleize('nationale nederlanden'))) return 'Nationale Nederlanden'
  if (handleizedString.includes(handleize('bcmglobal b.v.'))) return 'BCM Global'
  if (handleizedString.includes(handleize('obvion n.v.'))) return 'Obvion'
  if (handleizedString.includes(handleize('de volksbank n.v.'))) return 'Volksbank'

  return string
}

export const alphabetical = (a: any, b: any) => {
  if (a.toLowerCase() < b.toLowerCase()) {
    return -1;
  }
  if (a.toLowerCase() > b.toLowerCase()) {
    return 1;
  }
  return 0;
}


export function useGtm() {
  const location = useLocation();

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const gtmScript = document.createElement('script');

      gtmScript.id = 'gtm';

      gtmScript.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-MNMKR9RK');`;

      document.head.appendChild(gtmScript);

      return () => {
        document.head.removeChild(gtmScript);
      };
    }
  }, [location]);

  return null;
}